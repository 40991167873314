<template>
	<div class="trialdata">
		<!-- 导航栏 -->
		<van-nav-bar
		class="nav_bar_color"
		title="试驾数据"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		/>
		
		<!-- 车型 -->
		<div class="car__model">
			<img class="car__model__logo" :src="info.car_images" mode="widthFix">
			<div class="car__model__con">{{info.model_name}} {{info.collocation_name}}</div>
		</div>
		
		<!-- 选项 -->
		<div class="opts">
			<div class="opts__title">
				<img src="@/assets/trial/icon_time.png" style="width: 30px;height: 30px;" mode="">
				<div class="opts__title__con">
					体验时长
				</div>
			</div>
			<div class="opt">
				<div class="opt__input">
					<input
						placeholder-style="font-family: HYQiHeiY3-55; color: #535353;"
						type="number"
						max="120"
						placeholder="体验时长"
						v-model="form.cruise_duration"
					/>
				</div>
				<div class="opt__init">
					请输入本次体验的时长
				</div>
			</div>
			
		</div>
		<div style="margin: 0 auto; width: 100%; height: 1px; background: #333333;"></div>
		
		<!-- 选项 -->
		<div class="opts">
			<div class="opts__title">
				<img src="@/assets/trial/icon_juli.png" style="width: 38px;height: 35px;" mode="">
				<div class="opts__title__con">
					试驾距离
				</div>
			</div>
			<div class="opt">
				<div class="opt__input">
					<input
						placeholder-style="font-family: HYQiHeiY3-55; color: #535353;"
						type="number"
						placeholder="试驾距离"
						v-model="form.cruise_distance"
					/>
				</div>
				<div class="opt__init">
					请输入本次试驾的距离
				</div>
			</div>
			
		</div>
		
		<!-- 保存 -->
		<div style="height: 144px;"></div>
		<div class="savebtn"  style="height: 71px;" :style="{'top':(docmHeight - 71) + 'px'}">
			<div @click="handSave">保存</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			info: {
				number: '',
			},
			form: {
				cruise_duration: '',
				cruise_distance: '',
			},
			isedit:false
		};
	},
	computed: {    
			docmHeight() {
				console.log(this.$store.state.height)
				return this.$store.state.height;
			}
		},
	mounted() {
		if (this.$route.query?.number) this.info.number = this.$route.query.number
		this.getTrialInfo()
		this.getTrialReport()
	},
	methods: {
		// 保存
		async handSave(e) {
			if(this.form.cruise_duration == '') return this.$toast.fail('时长不能为空')
			if(parseInt(this.form.cruise_duration) > 120) return this.$toast.fail('时长最大120')
			if(this.form.cruise_distance == '') return this.$toast.fail('距离不能为空')
			if(parseInt(this.form.cruise_distance) > 100) return this.$toast.fail('距离最大100')
			this.form.number = this.info.number
			if(this.isedit){
				const {data: result} = await this.$http.post('/api/scsj/create_report_1_5', this.form)
				if (result.code == 1) {
					this.$toast.success('创建成功')
					this.$router.replace({
						path: `/trial-report-super?number=${this.info.number}`
					})
				}
			}else{
				const {data: result} = await this.$http.post('/api/scsj/edit_report_1_5', this.form)
				if (result.code == 1) {
					this.$toast.success('保存成功')
					this.$router.replace({
						path: `/trial-report-super?number=${this.info.number}`
					})
				}
			}
			
		},
		
		// 试驾详情
		async getTrialInfo(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_scsj_info_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.info = { ...this.info, ...result.data.res }
				this.info.car_images = this.serverUrl(this.info.car_images)
			}
		},
		
		// 试驾报告
		async getTrialReport(e) {
			const {data: result} = await this.$http.post('/api/scsj/get_report_1_7', {
				number: this.info.number
			})
			if (result.code == 1) {
				this.form.cruise_duration = result.data.res.cruise_duration
				this.form.cruise_distance = result.data.res.cruise_distance
				if(this.form.cruise_duration == null && this.form.cruise_distance==null){
					this.isedit = true
				}else{
					this.form.cruise_duration = this.form.cruise_duration
					this.form.cruise_distance = this.form.cruise_distance
				}
			}
		},
	}
}
</script>

<style lang="scss" scoped>
.car__model{
	width: 100%;
	height: 136px;
	box-sizing: border-box;
	background: #222;
	display: flex;
	align-items: center;
	justify-content: center;
	&__logo{
		width: 200px;
		margin-right: 40px;
	}
	&__con{
		font-size: 30px;
		font-family: HYQiHeiY3-45;
		color: #FFFFFF;
	}
}
.opts{
	padding: 50px 0 60px;
	text-align: center;
	&__title{
		&__con{
			margin-top: 20px;
			font-size: 36px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 36px;
		}
	}
	.opt{
		&__input{
			margin: 48px auto 0;
			width: 276px;
			border-bottom: 1px solid #666;
			padding: 12px 0;
			input{
				width: 100%;
				text-align: center;
				height: 40px;
				font-size: 40px;
				font-family: HYQiHeiY3-45;
				background-color: rgba(1, 1, 1, 0);
				border: none;
				color: #fff;
			}
		}
		&__init{
			margin: 24px auto 0;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			color: #ECECEC;
			line-height: 36px;
		}
	}
}
.savebtn{
	position: fixed;
	box-sizing: border-box;
	z-index: 1000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 40px;
	width: 100%;
	div{
		width: 100%;
		background: #FA0037;
		box-sizing: border-box;
		height: 91px;
		line-height: 91px;
		text-align: center;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		color: #FFFFFF;
	}
}
</style>
